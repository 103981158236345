<template>
	<S2SForm title="CIT Providers">
		<v-flex xs12 class="text-xs-left text-right pb-2">
			<v-btn class="ml-0" :block="$vuetify.breakpoint.xsOnly" color="accent" :to="{ name: 'create-cit-provider' }" cy-data="add-cash-centre"
				>Add CIT Provider</v-btn
			>
		</v-flex>

		<v-data-table :headers="headers" :items="citProviders" hide-default-footer class="elevation-1" :loading="status.loading">
			<template v-slot:item.action="{ item }">
				<v-btn text medium @click="editProvider(item.id)">
					<v-icon>edit</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import citSchema from "@/static/config/forms/cit-schema.json";
import { CITBody } from "../../api/safes-api";

export default Vue.extend({
	name: "CITProvider",

	data: function() {
		return {
			citSchema: citSchema,
			description: "",

			headers: [
				{
					text: "Id",
					value: "id",
					align: "left"
				},
				{
					text: "Description",
					value: "description",
					align: "left"
				},
				{
					text: "Contact Name",
					value: "contactName",
					align: "left"
				},
				{
					text: "Contact Number",
					value: "contactNumber",
					align: "left"
				},
				{
					text: "Email",
					value: "email",
					align: "left"
				},
				{
					text: "",
					value: "action",
					align: "right"
				}
			]
		};
	},

	computed: {
		citProviders: function() {
			return this.$store.state.safes.citProviders;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	mounted: function() {
		this.$store.dispatch("safes/fetchCITProviders");
	},

	methods: {
		editProvider(id) {
			this.$router.push({ name: "edit-cit-provider", params: { providerId: id.toString() } });
		}
	}
});
</script>
